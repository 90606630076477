import NavHelpdesk from "./navhelpdesk";
import NavHelpdeskAdmin from "./navadmin";
import NavHelpdeskOwner from "./navowner";
import { useEffect, useState } from "react";
import { getCookie } from "react-use-cookie";
import endpoint from "../endpoint";
import axios from "axios";
import Heading from "./heading";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";




const EditPengguna = () => {

    const params = useParams();
    const [refreshKey, setRefreshKey] = useState(1);

  useEffect(() => {
    const BarrierToken = getCookie("token");
    axios
      .get(`${endpoint}/rest/info`, {
        headers: { Authorization: `Bearer ${BarrierToken}` },
      })
      .then((data) => {
        let userdata = data.data;
        if (userdata.foto_profil_img !== null) {
          const imagepath = {
            foto_profil_img: endpoint + userdata.foto_profil_img,
          };
          delete data.data.foto_profil_img;
          Object.assign(userdata, imagepath);
          setState(data.data);
        } else {
          const imagepath = {
            foto_profil_img: "/user.png",
          };
          delete data.data.foto_profil_img;
          Object.assign(userdata, imagepath);
          setState(data.data);
        }
      })
      .catch((error) => {});
  }, []);

  const [pengguna, setPengguna] = useState({
    alamat: "",
    alamat_email: "",
    created_at: "",
    id_pengguna: "",
    nama: "",
    nama_pengguna: "",
    no_telepon: "",
    remember_me_token: "",
    role: "",
    updated_at: "",
    foto_profil_img : ""
  })

  useState(() => {

    const BarrierToken = getCookie("token");
    axios.get(endpoint+"/rest/pengguna/fetch/"+params.id,
    {
        headers: { Authorization: `Bearer ${BarrierToken}` },
    }).then(value => {

        console.log(value.data)
        const imagepath = {
          foto_profil_img: endpoint + value.data.foto_profil_img,
        };
        delete value.data.foto_profil_img;
        Object.assign(value.data, imagepath);
        setPengguna(value.data);

    }).catch(error =>{
        toast.error("Error fetching data!");
    })

  },[refreshKey])

  const [state, setState] = useState({
    alamat: "",
    alamat_email: "",
    foto_profil_img: "",
    id_foto_profil: "",
    nama: "",
    nama_pengguna: "",
    no_telepon: "",
    role: "",
  });



  const handleChange = (e) => {
    if (e.target.files) {
      setPengguna({ ...pengguna, [e.target.name]: e.target.files[0] });
    } else {
      setPengguna({ ...pengguna, [e.target.name]: e.target.value });
    }
  };

  const ImageChange = (event) => {
    const file = event.target.files[0];
    console.log(file)
    let url = URL.createObjectURL(file);
    setPengguna({ foto_profil_img: url });
  };

  const EditPengguna = (event) => {
    let BarrierToken = getCookie("token");
    event.preventDefault();
    console.log(event);
    var data = new FormData(event.target);
    axios.put(`${endpoint}/rest/pengguna/edit/${params.id}`, data, {
      headers: { Authorization: `Bearer ${BarrierToken}` },
    })
      .then((data) => {
        URL.revokeObjectURL(state.id_foto_profil)
        console.log(data);
        toast.success(data.data.success[0]?.message);
        setRefreshKey((oldKey) => oldKey + 1);
      })
      .catch((error) => {});
  };




  return (
    <>
      {state.role === "Admin" ? (
        <NavHelpdeskAdmin
          username={state.nama_pengguna}
          role={state.role}
          image={state.foto_profil_img}
        />
      ) : (
        ""
      )}
      {state.role === "Pelanggan" || state.role === "Teknisi" ? (
        <NavHelpdesk
          username={state.nama_pengguna}
          role={state.role}
          image={state.foto_profil_img}
        />
      ) : (
        ""
      )}
      {state.role === "Owner" ? (
        <NavHelpdeskOwner
          username={state.nama_pengguna}
          role={state.role}
          image={state.foto_profil_img}
        />
      ) : (
        ""
      )}
      <Heading pageName="Pengguna" />
      <Toaster position="top-right" reverseOrder={false} />


      <div className="max-w-2xl mx-auto bg-white p-16 rounded">
        <div className="bg-slate-700 shadow-sm rounded-md mb-4">
          <h1 className="text-lg text-white">Edit Pengguna</h1>
        </div>
        <div className="bg-white shadow py-5 sm:rounded-lg sm:p-6">
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form
              onSubmit={EditPengguna}
              className="space-y-6"
              action="#"
              method="POST"
            >
              <div className="mb-4">
                {pengguna.foto_profil_img?.length > 10 ? (
                  <img
                    className="mx-auto rounded-full w-16 object-cover object-center"
                    src={pengguna.foto_profil_img}
                    alt="Avatar Upload"
                  />
                ) : (
                  <img
                    className="mx-auto rounded-full w-16 object-cover object-center"
                    src={"/user.png"}
                    alt="Avatar Upload"
                  />
                )}
              </div>
              <label className="cursor-pointer mt-6">
                <span className="mt-2 leading-normal px-4 py-2 bg-blue-500 text-white text-sm rounded-full">
                  Upload Foto Profil
                </span>
                <input
                  type="file"
                  name="foto_profile"
                  className="hidden"
                  onChange={ImageChange}
                />
              </label>
              <div className="grid grid-cols-2 gap-2">
                <div className="">
                  <label
                    htmlFor="nama"
                    className="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Nama
                  </label>
                  <input
                    onChange={handleChange}
                    name="nama"
                    type="text"
                    value={pengguna.nama}
                    id="nama"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="nama_pengguna"
                    className="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Nama Pengguna
                  </label>
                  <input
                    onChange={handleChange}
                    name="nama_pengguna"
                    type="text"
                    value={pengguna.nama_pengguna}
                    id="nama_pengguna"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
              <div className="">
                <label
                  htmlFor="alamat"
                  className="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Alamat
                </label>
                <input
                  onChange={handleChange}
                  name="alamat"
                  type="text"
                  value={pengguna.alamat}
                  id="alamat"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div className="">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Email
                  </label>
                  <input
                    onChange={handleChange}
                    name="alamat_email"
                    type="email"
                    value={pengguna.alamat_email}
                    id="alamat_email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="no_telepon"
                    className="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Nomor Telepon
                  </label>
                  <input
                    onChange={handleChange}
                    name="no_telepon"
                    type="text"
                    value={pengguna.no_telepon}
                    id="no_telepon"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div className="">
                  <label
                    htmlFor="role"
                    className="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Role
                  </label>
                  <select
                      required
                      name="role"
                      id="role"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      {pengguna.role === "Admin" ? (
                        <option value="Admin" selected>Admin</option>
                      ) : (
                        <option value="Admin">Admin</option>
                      )}
                      {pengguna.role === "Teknisi" ? (
                        <option value="Teknisi" selected>Teknisi</option>
                       ) : (
                        <option value="Teknisi">Teknisi</option>
                       )}
                      {pengguna.role === "Pelanggan" ? (
                        <option value="Pelanggan" selected>Pelanggan</option>
                       ) : (
                        <option value="Pelanggan">Pelanggan</option>
                       )}
                       {pengguna.role === "Owner" ? (
                        <option value="Pelanggan" selected>Owner</option>
                       ) : (
                        <option value="Pelanggan">Owner</option>
                       )}
                      
                    </select>
                </div>

                <div className="">
                  <label
                    htmlFor="kata_sandi"
                    className="block mb-2 text-left text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Kata Sandi
                  </label>
                  <input
                    onChange={handleChange}
                    name="kata_sandi"
                    type="password"
                    id="kata_sandi"
                    placeholder="Kosongkan jika tidak diubah"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                </div>

              <button
                type="submit"
                className="px-8 py-3 bg-blue-500 text-white rounded"
              >
                Edit Pengguna
              </button>
            </form>
          </div>
        </div>
      </div>


           
      
                    
    </>
  );
};
export default EditPengguna;
