import Navbar from "./Navbar";
import LandingHarga from "./landing/Harga";
import Footer from "./Footer";

const Harga = () => {

    document.title = "Harga - PT Amanna Media Link"
    return (
        <>
        <Navbar/>
        <LandingHarga/>
        <Footer/>
        </>
    )
}
export default Harga;