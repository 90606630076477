import Navbar from "./Navbar";
import LandingKontak from "./landing/Kontak";
import Footer from "./Footer";

const Kontak = () => {

    document.title = "Kontak - PT Amanna Media Link"
    return (
        <>
        <Navbar/>
        <LandingKontak/>
        <Footer/>
        </>
    )
}

export default Kontak