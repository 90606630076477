import { setCookie } from "react-use-cookie";
import { Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
const Logout = () => {
  const [auth, setAuth] = useState(true);
  useEffect(() => {
    setCookie("token", "");
    setCookie("data", "");
    setAuth(false);
  }, []);
  return <>{auth ? "" : <Navigate to={"/helpdesk/login"} />}</>;
};

export default Logout;
