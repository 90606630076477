import Navbar from "./Navbar";
import LandingHome from "./landing/Home";
import Footer from "./Footer";
document.title = "Beranda - PT Amanna Media Link"
const Home = () => {
    return(
        <>
        <Navbar/>
        <LandingHome/>
        <Footer/>
        </>
    )
}

export default Home;