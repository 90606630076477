const LandingLayanan = () => {
    
    return(
        <>
        <section className="text-white body-font bg-slate-800">
        {" "}
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
              <img
                className="object-cover object-center rounded"
                alt="hero"
                src="/assets/service.gif"
              />
            </div>
            <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-5 md:mb-0 items-center text-center">
              <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
                Layanan (Service)
              </h1>
              <h2 className="title-font sm:text-2xl text-2xl mb-4 font-medium text-white">
                PT AMANNA MEDIA LINK
              </h2>
              <p className="mb-8 leading-relaxed">
                Layanan Kami berupa Internet, VPN(Virtual Private Network),
                Infrastruktur (Mikrotik)
              </p>
              <div className="flex justify-center">
                <button
                  onClick={() => {
                    window.open("https://wa.me/6281225412414", "_blank");
                  }}
                  type="button"
                  className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2"
                >
                  <img
                    alt="whatsapp"
                    src="/assets/whatsapp.gif"
                    className="w-10 h-10"
                  ></img>
                  Kontak Kami Melalui Whatsapp
                </button>
              </div>
            </div>
        </div>
      </section>{" "}
      <section className="text-white bg-slate-800 body-font">
        {" "}
        <div className="container px-5 py-24 mx-auto ">
          <div className="flex flex-col text-center w-full mb-5">
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-white">
              Apa saja layanan kami?
            </h1>
          </div>
            <div className="flex flex-wrap -m-4">
              <div className="p-4 md:w-1/3">
                <div className="flex rounded-lg text-black h-full bg-white p-8 flex-col">
                  <div className="flex items-center mb-3">
                    <div className="w-20 h-20 mr-3 inline-flex items-center justify-center rounded-full bg-gray-100 text-white flex-shrink-0">
                      <img alt="support" src="/assets/signal.gif"></img>
                    </div>
                    <h2 className="text-gray-900 text-lg title-font font-large">
                      Akses Internet
                    </h2>
                  </div>
                  <div className="flex-grow">
                    <p className="leading-relaxed text-base">
                      Dapatkan akses internet murah dengan uptime 99.99% dan
                      biaya yang terjangkau
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-4 md:w-1/3">
                <div className="flex rounded-lg h-full bg-white p-8 flex-col">
                  <div className="flex items-center mb-3">
                    <div className="w-20 h-20 mr-3 inline-flex items-center justify-center rounded-full bg-gray-100 text-black flex-shrink-0">
                      <img alt="speedtest" src="/assets/vpn.gif"></img>
                    </div>
                    <h2 className="text-gray-900 text-lg title-font font-medium">
                      VPN (Virtual Private Network)
                    </h2>
                  </div>
                  <div className="flex-grow">
                    <p className="leading-relaxed text-base text-black">
                      Berselancar dengan aman dan terhubung dengan private
                      network perusahaan anda dimanapun berada
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-4 md:w-1/3">
                <div className="flex rounded-lg h-full bg-white p-8 flex-col">
                  <div className="flex items-center mb-3">
                    <div className="w-20 h-20 mr-3 inline-flex items-center justify-center rounded-full bg-gray-100 text-white flex-shrink-0">
                      <img alt="speedtest" src="/assets/infra.gif"></img>
                    </div>
                    <h2 className="text-gray-900 text-lg title-font font-medium">
                      Infrastruktur
                    </h2>
                  </div>
                  <div className="flex-grow">
                    <p className="leading-relaxed text-base text-black">
                      Kami menyediakan jasa untuk membangun infrastruktur
                      jaringan pada perusahaan anda
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>
        </>
    )

}
export default LandingLayanan;