import { Fragment, useState, useEffect } from "react";
import endpoint from "../endpoint";
import { getCookie, setCookie } from "react-use-cookie";
//import ContentLoader from "react-content-loader";
import { Navigate } from "react-router-dom";
import NavHelpdesk from "./navhelpdesk";
import NavHelpdeskAdmin from "./navadmin";
import NavHelpdeskOwner from "./navowner";
import Heading from "./heading";
import axios from "axios";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import toast, { Toaster } from "react-hot-toast";



const MonitoringJaringan = () => {
  useEffect(() => {
    const BarrierToken = getCookie("token");
    axios
      .get(`${endpoint}/rest/info`, {
        headers: { Authorization: `Bearer ${BarrierToken}` },
      })
      .then((data) => {
        let userdata = data.data;
        if (userdata.foto_profil_img !== null) {
          const imagepath = {
            foto_profil_img: endpoint + userdata.foto_profil_img,
          };
          delete data.data.foto_profil_img;
          Object.assign(userdata, imagepath);
          setState(data.data);
        } else {
          const imagepath = {
            foto_profil_img: "/user.png",
          };
          delete data.data.foto_profil_img;
          Object.assign(userdata, imagepath);
          setState(data.data);
        }
      })
      .catch((error) => {});
  }, []);

  let [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  function openModal(id) {
    setselectId(id);
    setIsOpen(true);
  }

  
  const [selectId, setselectId] = useState("");
  const [state, setState] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [refreshKey, setRefreshKey] = useState(0);
  const [lisClient, setListClient] = useState([]);

  const [isLoading, setLoading] = useState(false);
  const [auth, setAuth] = useState(true);


  useEffect(() => {
    let cookie = getCookie("token");
    console.log(getCookie("data"));
    console.log(cookie);
    if (cookie == null && cookie === undefined) {
      setAuth(false);
      setCookie("token", "");
      setCookie("data", "");
    } else {
      setLoading(true);
      fetch(endpoint + "/rest/aduan/overview", {
        headers: { Authorization: `Bearer ${cookie}` },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.errors) {
            setAuth(false);
            setCookie("token", "");
            setCookie("data", "");
          } else {
            setAuth(true);
            setLoading(false);
          }
        })
        .catch((err) => {
          setAuth(false);
          setCookie("token", "");
          setCookie("data", "");
        });
      fetch(endpoint + "/rest/mikrotik/pelanggan?page=" + currentPage, {
        headers: { Authorization: `Bearer ${cookie}` },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.errors) {
            setAuth(false);
            setCookie("token", "");
            setCookie("data", "");
          } else {
            setAuth(true);
            setListClient(data.data);
            setCurrentPage(data.meta.current_page);
            setLastPage(data.meta.last_page);
          }
        });
    }
  }, [refreshKey, currentPage]);

  const Paginate = (id) => {
    console.log(id);
    setCurrentPage(id);
    setRefreshKey((oldKey) => oldKey + 1);
  };

  const Search = (value) => 
  {
    console.log(value.target.value);
    axios.post(`${endpoint}/rest/mikrotik/pelanggan/search`, {
      client : value.target.value
    }).then(data => {
      setListClient(data.data.data);
      setCurrentPage(data.data.meta.current_page);
      setLastPage(data.data.meta.last_page);
    }).catch(error => {

    })
  }


  return (
    <>
      {state.role === "Admin" ? (
        <NavHelpdeskAdmin
          username={state.nama_pengguna}
          role={state.role}
          image={state.foto_profil_img}
        />
      ) : (
        ""
      )}
      {state.role === "Pelanggan" || state.role === "Teknisi" ? (
        <NavHelpdesk
          username={state.nama_pengguna}
          role={state.role}
          image={state.foto_profil_img}
        />
      ) : (
        ""
      )}
      {state.role === "Owner" ? (
        <NavHelpdeskOwner
          username={state.nama_pengguna}
          role={state.role}
          image={state.foto_profil_img}
        />
      ) : (
        ""
      )}
      <Heading pageName="Monitoring Jaringan" />

      <Toaster position="top-right" reverseOrder={false} />

      <section className="py-20 bg-blueGray-50">
        <div className="container px-4 mx-auto mb-3">
          <h2 className="mb-1 text-center text-2xl font-bold text-black">
            {" "}
            List Client{" "}
          </h2>{" "}
          <br />
          <div className="flex justify-center">
            <div className="mb-3 xl:w-96">
              <div className="input-group relative flex flex-wrap items-stretch w-full mb-4">
                <input onChange={Search} type="search" className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder="Search" aria-label="Search" aria-describedby="button-addon2" />
              </div>
            </div>
          </div>

          <table className="w-full bg-white rounded shadow">
            <thead>
              <tr className="text-left text-white text-bold text-xs bg-slate-800">
                <th className="pl-6 py-4 font-normal">#ID</th>
                <th className="px-4 py-4 font-normal">IP Address</th>
                <th className="px-4 py-4 font-normal">Nama Client</th>
                <th className="px-4 py-4 font-normal">Tipe</th>
                <th className="px-4 py-4 font-normal">Status</th>
              
              </tr>
            </thead>
            <tbody>
              {lisClient?.map((value, key) => {
                return (
                  <tr key={key} className="border-t border-blueGray-100">
                    <td className="flex px-6 py-4 text-xs">{value.id}</td>
                    <td className="px-4 py-2 text-xs text-left font-semibold">
                      {value.address}
                    </td>
                    <td className="px-4 py-2 text-xs text-left font-semibold">
                      {value.comment}
                    </td>
                    <td className="px-4 py-2 text-xs text-left font-semibold">
                      {value.list}
                    </td>
                    {value.disabled === 0 ? (
                      <td className="px-4 py-2 text-xs text-left font-semibold">
                        <span className="text-white px-4 py-2 bg-green-600">Aktif</span>
                      </td>
                    ) : (
                      <td className="px-4 py-2 text-xs text-left font-semibold">
                        <span className="text-white px-4 py-2 bg-red-600">Tidak Aktif</span>
                      </td>
                    )}
                    

                    
              
                    


                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex justify-center items-center space-x-1">
          {[...Array(lastPage)].map((key, value) => (
            <button
              onClick={() => Paginate(value + 1)}
              key={value + 1}
              className={
                value + 1 === currentPage
                  ? "px-4 py-2 text-white bg-blue-400 rounded-md hover:bg-blue-400 hover:text-white"
                  : "px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-blue-400 hover:text-white"
              }
            >
              {value + 1}
            </button>
          ))}
        </div>
      </section>

    </>
  );
};
export default MonitoringJaringan;
