import Navbar from "./Navbar";
import LandingLayanan from "./landing/Layanan";
import Footer from "./Footer";

const Layanan = () => {

    document.title = "Layanan - PT Amanna Media Link";
    return(
        <>
        <Navbar/>
        <LandingLayanan/>
        <Footer/>
        </>
    )
}

export default Layanan;