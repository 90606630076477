import NavHelpdesk from "./navhelpdesk";
import NavHelpdeskAdmin from "./navadmin";
import NavHelpdeskOwner from "./navowner";
import { useParams, Navigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { getCookie, setCookie } from "react-use-cookie";
import axios from "axios";
import endpoint from "../endpoint";
import Zoom from "react-medium-image-zoom";
import "../../zoom.css";
import moment from "moment";
import { useDropzone } from "react-dropzone";
import Heading from "./heading";
import { Dialog, Transition } from "@headlessui/react";
import toast, { Toaster } from "react-hot-toast";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};
const DetailAduanComponents = () => {
  const params = useParams();

  let [isOpen, setIsOpen] = useState(false);
  let [selesaiModal, setSelesaiModal] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function openModalSelesai() {
    setSelesaiModal(true);
  }

  function closeSelesaiModal() {
    setSelesaiModal(false);
  }

  const TeruskanKeAdmin = () => {
    closeModal();
    const BarrierToken = getCookie("token");
    console.log(BarrierToken);
    toast.success("Done! Laporan diteruskan ke Teknisi");
    axios
      .put(
        endpoint + "/rest/aduan/teruskanKeTeknisi/" + params.id,
        {},
        {
          headers: { Authorization: `Bearer ${BarrierToken}` },
        }
      )
      .then((value) => {
        console.log(value);
        setRefreshKey((oldKey) => oldKey + 1);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const SelesaikanAduan = () => {
    closeSelesaiModal();
    console.log("Selesi CUk");
    const BarrierToken = getCookie("token");
    console.log(BarrierToken);

    axios
      .put(
        endpoint + "/rest/aduan/selesaikan/" + params.id,
        {},
        {
          headers: { Authorization: `Bearer ${BarrierToken}` },
        }
      )
      .then((value) => {
        toast.success("Done! Laporan diteruskan ke Teknisi");
        setRefreshKey((oldKey) => oldKey + 1);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(() => {
    const BarrierToken = getCookie("token");
    axios
      .get(`${endpoint}/rest/info`, {
        headers: { Authorization: `Bearer ${BarrierToken}` },
      })
      .then((data) => {
        let userdata = data.data;
        if (userdata.foto_profil_img !== null) {
          const imagepath = {
            foto_profil_img: endpoint + userdata.foto_profil_img,
          };
          delete data.data.foto_profil_img;
          Object.assign(userdata, imagepath);
          setState(data.data);
        } else {
          const imagepath = {
            foto_profil_img: "/user.png",
          };
          delete data.data.foto_profil_img;
          Object.assign(userdata, imagepath);
          setState(data.data);
        }
      })
      .catch((error) => {});
  }, []);
  const [state, setState] = useState({
    alamat: "",
    alamat_email: "",
    foto_profil_img: "",
    id_foto_profil: "",
    nama: "",
    nama_pengguna: "",
    no_telepon: "",
    role: "",
  });

  const [refreshKey, setRefreshKey] = useState(0);

  const AddBalasan = (event) => {
    event.preventDefault();
    let BarrierToken = getCookie("token");
    const data = new FormData(event.target);
    files.map((file) => data.append("File[]", file));
    axios
      .post(endpoint + "/rest/tambah/balasan", data, {
        headers: {
          Authorization: `Bearer ${BarrierToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        setRefreshKey((oldKey) => oldKey + 1);
        event.target.reset();
        setFiles([]);
      });
  };

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          alt={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  const [listAduan, setlistAduan] = useState([]);
  const [listBalasan, setlistBalasan] = useState([]);
  const [listFileAduan, setlistFileAdian] = useState([]);
  const [auth, setAuth] = useState(true);
  useEffect(() => {
    let BarrierToken = getCookie("token");
    const API = `${endpoint}/rest/detail/aduan/${params.id}`;
    axios
      .get(API, {
        headers: { Authorization: `Bearer ${BarrierToken}` },
      })
      .then((data) => {
        setlistAduan(data.data);
        setlistBalasan(data.data.balasan);
        let fileList = JSON.parse(data.data.file_aduan);

        setlistFileAdian(fileList);
      })
      .catch((error) => {
        console.log(error);
        setAuth(false);
        setCookie("token", "");
        setCookie("data", "");
      });
  }, [params.id, refreshKey]);

  return (
    <>
      {auth ? "" : <Navigate to="/helpdesk/login"></Navigate>}

      {state.role === "Admin" ? (
        <NavHelpdeskAdmin
          username={state.nama_pengguna}
          role={state.role}
          image={state.foto_profil_img}
        />
      ) : (
        ""
      )}
      {state.role === "Pelanggan" || state.role === "Teknisi" ? (
        <NavHelpdesk
          username={state.nama_pengguna}
          role={state.role}
          image={state.foto_profil_img}
        />
      ) : (
        ""
      )}
      {state.role === "Owner" ? (
        <NavHelpdeskOwner
          username={state.nama_pengguna}
          role={state.role}
          image={state.foto_profil_img}
        />
      ) : (
        ""
      )}
      <Heading
        pageName="Aduan"
        username={state.nama_pengguna}
        role={state.role}
        image={state.foto_profil_img}
      />

      <Toaster position="top-right" reverseOrder={false} />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Teruskan ke Teknisi?
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Aduan ini akan diteruskan ke teknisi lapangan.
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center py-2 px-6 text-white bg-green-500 hover:bg-green-600 rounded-lg mr-2"
                      onClick={TeruskanKeAdmin}
                    >
                      Konfirmasi
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center py-2 px-6 text-white bg-red-500 hover:bg-red-600 rounded-lg"
                      onClick={closeModal}
                    >
                      Batal
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={selesaiModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeSelesaiModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Selesaikan aduan ini?
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Anda akan mengubah status aduan ke Selesai!
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center py-2 px-6 text-white bg-green-500 hover:bg-green-600 rounded-lg mr-2"
                      onClick={SelesaikanAduan}
                    >
                      Konfirmasi
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center py-2 px-6 text-white bg-red-500 hover:bg-red-600 rounded-lg"
                      onClick={closeSelesaiModal}
                    >
                      Batal
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <div className="flex mt-2 bg-white border-l-8 leading-relaxed border-[#0097e6] shadow-lg rounded-lg mx-2 my-2 md:mx-auto max-w-lg md:max-w-screen-md ">
        <div className="flex items-start px-4 py-6">
          {listAduan.foto_profil_url === null ? (
            listAduan.role === "Pelanggan" ? (
              <img
                className="w-12 h-12 rounded-full object-cover mr-4 shadow"
                src={"/user.png"}
                alt="avatar"
              />
            ) : (
              <img
                className="w-12 h-12 rounded-full object-cover mr-4 shadow"
                src={"/admin.png"}
                alt="avatar"
              />
            )
          ) : (
            <img
              className="w-12 h-12 rounded-full object-cover mr-4 shadow"
              src={endpoint + listAduan.foto_profil_url}
              alt="avatar"
            />
          )}
          <div className="">
            <div className="flex">
              <h2 className="text-lg font-semibold text-gray-900 -mt-1 ">
                {listAduan.nama}
                <span className="text-blue-600 text-md">
                  {" "}
                  ({listAduan.role})
                </span>
              </h2>
            </div>
            <div className="block text-left mb-2">
              <span className="text-sm text-gray-700">
                {moment(listAduan.created_at).fromNow()}
              </span>
            </div>

            <div className="inline-block mb-3">
              <span className="inline-block py-1 px-3 text-xs text-gray-600 bg-gray-100 font-semibold uppercase rounded-full">
                Kategori : {listAduan.kategori}
              </span>
            </div>

            <div className="inline-block px-3">
              <span className="inline-block py-1 px-3 text-xs text-gray-600 bg-gray-100 font-semibold uppercase rounded-full">
                Level : {listAduan.level_aduan}
              </span>
            </div>
            <div className="inline-block px-3">
              <span className="inline-block py-1 px-3 text-xs text-gray-600 bg-gray-100 font-semibold uppercase rounded-full">
                Status Aduan : {listAduan.status_aduan}
              </span>
            </div>
            <h2 className="text-lg text-left font-semibold text-gray-900 -mt-1 ">
              {listAduan.judul_aduan}
            </h2>
            <h3 className="text-sm text-left text-gray-900 -mt-1 mb-12">
              {listAduan.isi_aduan}
            </h3>

            {listFileAduan[0] !== null
              ? listFileAduan.map((value, key) => (
                  <Zoom key={key} className="float-left ">
                    <img
                      alt="file"
                      width={50}
                      height={50}
                      className="inline-block mr-4"
                      src={endpoint + value}
                    ></img>
                  </Zoom>
                ))
              : ""}
          </div>
        </div>
      </div>

      {listBalasan[0] !== null
        ? listBalasan.map((value, key) =>
            listAduan.id_pengguna !== value.id_pengguna ? (
              <div
                key={key}
                className="flex border-r-8 leading-relaxed border-[#ff4757] bg-white shadow-lg rounded-lg mx-2 my-2 md:mx-auto max-w-lg md:max-w-screen-md "
              >
                <div className="flex items-start px-4 py-6">
                  {value.foto_profil_url === null ? (
                    value.role === "Pelanggan" ? (
                      <img
                        className="w-12 h-12 rounded-full object-cover mr-4 shadow"
                        src={"/user.png"}
                        alt="avatar"
                      />
                    ) : (
                      <img
                        className="w-12 h-12 rounded-full object-cover mr-4 shadow"
                        src={"/admin.png"}
                        alt="avatar"
                      />
                    )
                  ) : (
                    <img
                      className="w-12 h-12 rounded-full object-cover mr-4 shadow"
                      src={endpoint + value.foto_profil_url}
                      alt="avatar"
                    />
                  )}
                  <div className="">
                    <div className="flex justify-between">
                      <h2 className="text-lg font-semibold text-gray-900 -mt-1 ">
                        {value.nama}
                        <span className="text-blue-600 text-md">
                          ({value.role})
                        </span>
                      </h2>
                    </div>
                    <p className="text-sm text-left text-gray-700 mb-2">
                      {moment(value.created_at).fromNow()}
                    </p>
                    <h3 className="text-sm text-left text-gray-900 -mt-1 mb-5">
                      {value.balasan}
                    </h3>
                    {JSON.parse(value.file_aduan)[0] !== null
                      ? JSON.parse(value.file_aduan).map((value, key) => (
                          <Zoom key={key} className="content-start">
                            <img
                              alt="file"
                              width={50}
                              height={50}
                              className=" inline-block mr-4"
                              src={endpoint + value}
                            ></img>
                          </Zoom>
                        ))
                      : ""}
                  </div>
                </div>
              </div>
            ) : (
              <div
                key={key}
                className="flex border-l-8 leading-relaxed border-[#0097e6] bg-white shadow-lg rounded-lg mx-2 my-2 md:mx-auto max-w-lg md:max-w-screen-md "
              >
                <div className="flex items-start px-4 py-6">
                  {value.foto_profil_url === null ? (
                    value.role === "Pelanggan" ? (
                      <img
                        className="w-12 h-12 rounded-full object-cover mr-4 shadow"
                        src={"/user.png"}
                        alt="avatar"
                      />
                    ) : (
                      <img
                        className="w-12 h-12 rounded-full object-cover mr-4 shadow"
                        src={"/admin.png"}
                        alt="avatar"
                      />
                    )
                  ) : (
                    <img
                      className="w-12 h-12 rounded-full object-cover mr-4 shadow"
                      src={endpoint + value.foto_profil_url}
                      alt="avatar"
                    />
                  )}
                  <div className="">
                    <div className="flex justify-between">
                      <h2 className="text-lg font-semibold text-gray-900 -mt-1 ">
                        {value.nama}
                        <span className="text-blue-600 text-md">
                          ({value.role})
                        </span>
                      </h2>
                    </div>
                    <p className="text-sm text-left text-gray-700 mb-2">
                      {moment(value.created_at).fromNow()}
                    </p>
                    <h3 className="text-sm text-left text-gray-900 -mt-1 mb-5">
                      {value.balasan}
                    </h3>
                    {JSON.parse(value.file_aduan)[0] !== null
                      ? JSON.parse(value.file_aduan).map((value, key) => (
                          <Zoom key={key} className="content-start">
                            <img
                              alt="file"
                              width={50}
                              height={50}
                              className=" inline-block mr-4"
                              src={endpoint + value}
                            ></img>
                          </Zoom>
                        ))
                      : ""}
                  </div>
                </div>
              </div>
            )
          )
        : ""}

      {state.role === "Admin" ? (
        <>
          <div className="flex mt-2 bg-white  rounded-lg mx-2 my-2 md:mx-auto max-w-lg md:max-w-screen-md">
            <button
              onClick={openModal}
              className="py-2 px-6 text-white bg-orange-500 hover:bg-orange-600 rounded-lg mr-2"
            >
              Teruskan Ke Teknisi
            </button>
            <button
              onClick={openModalSelesai}
              className="py-2 px-6 text-white bg-green-500 hover:bg-green-600 rounded-lg"
            >
              Tandai Sudah Selesai
            </button>
          </div>
        </>
      ) : (
        ""
      )}

    {state.role === "Teknisi" ? (
        <>
          <div className="flex mt-2 bg-white  rounded-lg mx-2 my-2 md:mx-auto max-w-lg md:max-w-screen-md">
            <button
              onClick={openModalSelesai}
              className="py-2 px-6 text-white bg-green-500 hover:bg-green-600 rounded-lg"
            >
              Tandai Sudah Selesai
            </button>
          </div>
        </>
      ) : (
        ""
      )}



      <section className="text-gray-700 b body-font">
        <div className=" bg-white shadow-lg rounded-lg mx-2 my-2 md:mx-auto max-w-lg md:max-w-screen-md ">
          <form action="" method="POST" onSubmit={AddBalasan}>
            <input type={"hidden"} name="id_aduan" value={params.id}></input>
            <div className="">
              <div className="flex flex-wrap w-full">
                <div className="p-2 w-full ">
                  <div className="content-center m-auto">
                    <label
                      htmlFor="message"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Balasan
                    </label>
                    <textarea
                      id="message"
                      name="balasan"
                      className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                      required
                    ></textarea>
                    <label
                      htmlFor="foto"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Foto Balasan (Jika ada)
                    </label>
                    <section className="container">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <div className="w-full">
                          <label className="flex justify-center items-center h-32 w-full px-4 py-5 transition bg-gray-100  border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                            <span className="flex items-center space-x-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 text-gray-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                />
                              </svg>
                              <span className="font-medium text-gray-600">
                                Drop gambar disini atau
                                <span className="text-blue-600 ">
                                  {" "}
                                  Pilih File
                                </span>
                              </span>
                            </span>
                            <input type="file" className="hidden" />
                          </label>
                        </div>
                      </div>
                      <aside style={thumbsContainer}>{thumbs}</aside>
                    </section>
                  </div>
                </div>
                <div className="p-2 w-full">
                  <button className="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                    Kirim
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default DetailAduanComponents;
