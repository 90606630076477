const Footer = () => {
  return (
    <>
      <footer className=" bg-white-900 body-font">
        {" "}
        <section className="text-white body-font bg-slate-900">
          <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
              <div className="ml-7 content-end md:content-around">
                <img
                  className="flex items-end content-end"
                  alt="hero"
                  src="/assets/speedtest.gif"
                />
              </div>

              <div className="lg:flex-grow md:w-1/2 ml-7 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-5 md:mb-0 items-center text-center">
                <h2 className="title-font sm:text-2xl text-2xl mb-4 font-medium text-white">
                  Selalu terhubung dengan kami
                </h2>
                <p className="mb-8 leading-relaxed">
                  Anda dapat menghubungi kami melalui whatsapp dengan mengklik
                  tombol pada bawah ini!
                </p>
                <div className="flex justify-center">
                  <button
                    onClick={() => {
                      window.open("https://wa.me/6281225412414", "_blank");
                    }}
                    type="button"
                    className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2"
                  >
                    <img
                      alt="whatsapp"
                      src="/assets/whatsapp.gif"
                      className="w-10 h-10"
                    ></img>
                    Kontak Kami Melalui Whatsapp
                  </button>
                </div>
              </div>
          </div>
        </section>
        <div className="bg-gray-200">
          <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
            <p className="text-gray-500 text-sm text-center sm:text-left">
              &copy; 2022 PT Amanna Media Link
            </p>
            <span className="sm:ml-auto sm:mt-0 mt-2 sm:w-auto w-full sm:text-left text-center text-gray-500 text-sm">
              All Right Reserved
            </span>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;