import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link, useLocation } from "react-router-dom";

export default function NavHelpdeskOwner(props) {
  const navigation = [
    {
      name: "Dashboard",
      href: "/helpdesk/dashboard",
      current: false,
    },
    {
      name: "Aduan",
      href: "/helpdesk/aduan",
      current: false,
    },
    {
      name: "Profil",
      href: "/helpdesk/profil",
      current: false,
    },
    {
      name: "Pengguna",
      href: "/helpdesk/pengguna",
      current: false,
    },
    {
      name: "Monitoring Jaringan",
      href: "/helpdesk/monitor",
      current: false,
    },
    {
      name: "Bandwith Monitor",
      href: "/helpdesk/bandwith-monitor",
      current: false,
    },
    {
      name: "Logout",
      href: "/helpdesk/logout",
      current: false,
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const router = useLocation();

  return (
    <Disclosure as="nav" className="bg-slate-900">
      {({ open }) => (
        <>
          {" "}
          <div className="max-w-7xl mx-auto py-1 px-2 sm:px-6 lg:px-8">
            {" "}
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <img
                    className="block lg:hidden h-8 w-auto"
                    src="/logo.png"
                    alt="Logo"
                  />
                  <img
                    className="hidden lg:block h-8 w-auto"
                    src="/logo.png"
                    alt="Logo"
                  />
                  <span className="ml-3 text-l text-white">Amanna</span>
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="items-center flex space-x-4">
                    {navigation.map((item, key) => (
                      <Link
                        to={item.href}
                        key={item.name}
                        className={classNames(
                          item.href === router.pathname
                            ? "bg-gray-800 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "px-3 py-2 rounded-md text-sm font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <button>
                  <img
                    className="w-10 h-10 -mb-2 rounded-full mr-1 inline"
                    src={props.image}
                    alt="Avatar"
                  />
                  <span className="text-white -mt-2  font-semibold text-lg uppercase">
                    {props.username}
                  </span>
                  <span className="ml-12 -mt-3 block text-blue-300 font-semibold text-sm">
                    {" "}
                    {props.role}
                  </span>
                </button>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item, key) => (
                <Link key={key} to={item.href}>
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                </Link>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
