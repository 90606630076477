import { useState } from "react";
import { Tab } from "@headlessui/react";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const LandingCakupan = () => {
  let [categories] = useState({
    Sukoharjo: [
      {
        id: 1,
        title: "Sukoharjo Kota",
      },
      {
        id: 2,
        title: "Tawang Sari",
      },
      {
        id: 3,
        title: "Dan Sekitarnya",
      },
    ],
    Karanganyar: [
      {
        id: 1,
        title: "Jatipuro",
      },
      {
        id: 2,
        title: "Jumapolo",
      },
      {
        id: 2,
        title: "Dan Sekitarnya",
      },
    ],
    Klaten: [
      {
        id: 1,
        title: "Klaten Kota",
      },
      {
        id: 2,
        title: "Ponggok",
      },
      {
        id: 3,
        title: "Bayat",
      },
      {
        id: 4,
        title: "Cawas",
      },
      {
        id: 5,
        title: "Trucuk",
      },
      {
        id: 4,
        title: "Dan Sekitarnya",
      },
    ],
    "Gunung Kidul": [
      {
        id: 1,
        title: "Ngawen",
      },
      {
        id: 2,
        title: "Semin",
      },
      {
        id: 3,
        title: "Karang Mojo",
      },
      {
        id: 4,
        title: "Dan Sekitarnya",
      },
    ],
  });

  return (
    <>
      {" "}
      <section className="text-white body-font bg-slate-800">
        {" "}
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mr-20">
              <img
                className="ml-9 content-center object-center rounded "
                alt="hero"
                src="/assets/coverage.gif"
              />
            </div>
            <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-5 md:mb-0 items-center text-center">
              <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
                Cakupan (Coverage)
              </h1>
              <h2 className="title-font sm:text-2xl text-2xl mb-4 content-center font-medium text-white">
                PT AMANNA MEDIA LINK
              </h2>
              <p>
                Cakupan kami meliputi Kab. Sukoharjo, Kab. Karanganyar, Kab.
                Klaten, Kab. Gunung Kidul dan lainya menyusul
              </p>
              <div className=" w-full max-w-md px-2 py-16 sm:px-0">
                <Tab.Group>
                  <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
                    {Object.keys(categories).map((category) => (
                      <Tab
                        key={category}
                        className={({ selected }) =>
                          classNames(
                            "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                            "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-no" +
                              "ne focus:ring-2",
                            selected
                              ? "bg-white shadow"
                              : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                          )
                        }
                      >
                        {category}
                      </Tab>
                    ))}
                  </Tab.List>
                  <Tab.Panels className="mt-2">
                    {Object.values(categories).map((posts, idx) => (
                      <Tab.Panel
                        key={idx}
                        className={classNames(
                          "rounded-xl bg-white p-3",
                          "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-no" +
                            "ne focus:ring-2"
                        )}
                      >
                        <ul>
                          {posts.map((post) => (
                            <li
                              key={post.id}
                              className="relative rounded-md p-3 hover:bg-gray-100"
                            >
                              <h3 className="text-sm text-black font-medium leading-5">
                                {post.title}
                              </h3>

                              
                            </li>
                          ))}
                        </ul>
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>
              </div>
            </div>
        </div>
      </section>
      <section className="text-gray-700 body-font "></section>
        <section className="text-white body-font  ">
          <div className="container my-12 mx-auto px-4 md:px-12">
            <div className="flex flex-wrap -mx-1 lg:-mx-4">
              <div className="my-1 px-1 w-full md:w-1/4 lg:my-3 lg:px-3 lg:w-1/4">
                <article className="overflow-hidden rounded-lg shadow-lg">
                 

                    
                    <img
                      alt="Sukoharjo"
                      className="block w-full h-60"
                      src="/assets/sukoharjo.jpg"
                    />
 
                
                  <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                    <h1 className="text-lg text-black text-center">
                        Sukoharjo
                    </h1>
                  </header>
                </article>
              </div>

              <div className="my-1 px-1 w-full md:w-1/4 lg:my-3 lg:px-3 lg:w-1/4">
                <article className="overflow-hidden rounded-lg shadow-lg">
                    <img
                      alt="Placeholder"
                      className="block w-full h-60"
                      src="/assets/jatipuro.jpg"
                    />
                  <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                  <h1 className="text-lg text-black text-center">
                        Jatipuro
                    </h1>
                  </header>
                </article>
              </div>
              <div className="my-1 px-1 w-full md:w-1/4 lg:my-3 lg:px-3 lg:w-1/4">
                <article className="overflow-hidden rounded-lg shadow-lg">
                    <img
                      alt="Placeholder"
                      className="block h-60 w-full"
                      src="/assets/klaten.jpg"
                    />

                  <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                  <h1 className="text-lg text-black text-center">
                        Klaten
                    </h1>
                  </header>
                </article>
              </div>
              <div className="my-1 px-1 w-full md:w-1/4 lg:my-3 lg:px-3 lg:w-1/4">
                <article className="overflow-hidden rounded-lg shadow-lg">
                    <img
                      alt="Placeholder"
                      className="block h-60 w-full"
                      src="/assets/gunungkidul.jpg"
                    />
                  <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                  <h1 className="text-lg text-black text-center">
                        Gunung Kidul
                    </h1>
                  </header>
                </article>
              </div>
            </div>
          </div>
        </section>
    </>
  );
};

export default LandingCakupan;