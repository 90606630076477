import { useState, useEffect } from "react";
import endpoint from "../endpoint";
import { getCookie } from "react-use-cookie";
//import ContentLoader from "react-content-loader";
import NavHelpdesk from "./navhelpdesk";
import NavHelpdeskAdmin from "./navadmin";
import NavHelpdeskOwner from "./navowner";
import Heading from "./heading";
import axios from "axios";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);



const BandwithMonitor = () => {

  const [refreshKey, setRefreshKey] = useState(1);


  useEffect(() => {
    const BarrierToken = getCookie("token");
    axios
      .get(`${endpoint}/rest/info`, {
        headers: { Authorization: `Bearer ${BarrierToken}` },
      })
      .then((data) => {
        let userdata = data.data;
        if (userdata.foto_profil_img !== null) {
          const imagepath = {
            foto_profil_img: endpoint + userdata.foto_profil_img,
          };
          delete data.data.foto_profil_img;
          Object.assign(userdata, imagepath);
          setState(data.data);
        } else {
          const imagepath = {
            foto_profil_img: "/user.png",
          };
          delete data.data.foto_profil_img;
          Object.assign(userdata, imagepath);
          setState(data.data);
        }
      })
      .catch((error) => {});
  }, [refreshKey]);


  const [eth1RX, setEth1RX] = useState([]);
  const [eth1TX, setEth1TX] = useState([]);
  const [eth1label, setEth1Label] = useState([]);
  const [interval, setInterval] = useState('2h');

  const [portalrx, setportalrx] = useState([]);
  const [portaltx, setportaltx] = useState([]);

  const [managementx, setmanagementx] = useState([]);
  const [managemenrx, setmanagemenrx] = useState([]);

  const [areaklerongtx, setareaklerongtx] = useState([]);
  const [areaklerongrx, setareaklerongrx] = useState([]);

  const [ether3tx, setether3tx] = useState([]);
  const [ether3rx, setether3rx] = useState([]);

  useEffect(() => {
    axios.get('https://helpdesk.gils.dev/rest/mikrotik/createGraph/*17?interval='+interval) //ether1
    .then(value => {
      console.log(value.data)
      setEth1Label(value.data.label);
      setEth1RX(value.data.rx);
      setEth1TX(value.data.tx);
    });
    axios.get('https://helpdesk.gils.dev/rest/mikrotik/createGraph/*14?interval='+interval) //ether1
    .then(value => {
      console.log(value.data)
      setportalrx(value.data.rx);
      setportaltx(value.data.tx);
    });
    axios.get('https://helpdesk.gils.dev/rest/mikrotik/createGraph/*1E?interval='+interval) //ether1
    .then(value => {
      console.log(value.data)
      setmanagemenrx(value.data.rx);
      setmanagementx(value.data.tx);
    });

    axios.get('https://helpdesk.gils.dev/rest/mikrotik/createGraph/*11?interval='+interval) //ether1
    .then(value => {
      console.log(value.data)
      setareaklerongrx(value.data.rx);
      setareaklerongtx(value.data.tx);
    });

    axios.get('https://helpdesk.gils.dev/rest/mikrotik/createGraph/*19?interval='+interval) //ether1
    .then(value => {
      console.log(value.data)
      setether3rx(value.data.rx);
      setether3tx(value.data.tx);
    });

  }, [refreshKey])

   const ether1_options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Ether1 RX/TX',
      },
    },
  };
  const labels = eth1label;
  const ether1_data = {
    labels,
    datasets: [
      {
        label: 'Ether 1 Rx',
        data: eth1RX,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Ether 1 Tx',
        data: eth1TX,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  const portal_options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Portal RX/TX',
      },
    },
  };
  const portal_data = {
    labels,
    datasets: [
      {
        label: 'Portal Rx',
        data: portalrx,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Portal Tx',
        data: portaltx,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  const managemen_options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Managemen RX/TX',
      },
    },
  };
  const managemen_data = {
    labels,
    datasets: [
      {
        label: 'Managemen Rx',
        data: managemenrx,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Managemen Tx',
        data: managementx,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  const klerong_options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Area Klerong RX/TX',
      },
    },
  };
  const klerong_data = {
    labels,
    datasets: [
      {
        label: 'Area Klerong Rx',
        data: areaklerongrx,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Area Klerong Tx',
        data: areaklerongtx,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  const ether3_options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Ether3 RX/TX',
      },
    },
  };
  const ether3_data = {
    labels,
    datasets: [
      {
        label: 'Ether3 Rx',
        data: ether3rx,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Ether3 Tx',
        data: ether3tx,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };
  



  const [state, setState] = useState({});







  return (
    <>
      {state.role === "Admin" ? (
        <NavHelpdeskAdmin
          username={state.nama_pengguna}
          role={state.role}
          image={state.foto_profil_img}
        />
      ) : (
        ""
      )}
      {state.role === "Pelanggan" || state.role === "Teknisi" ? (
        <NavHelpdesk
          username={state.nama_pengguna}
          role={state.role}
          image={state.foto_profil_img}
        />
      ) : (
        ""
      )}
      {state.role === "Owner" ? (
        <NavHelpdeskOwner
          username={state.nama_pengguna}
          role={state.role}
          image={state.foto_profil_img}
        />
      ) : (
        ""
      )}
      <Heading pageName="Monitoring Jaringan" />

      <div class="grid grid-cols-2 gap-4">
      <div>
      <Line options={ether1_options} data={ether1_data} />
      </div>
      <div>
      <Line options={portal_options} data={portal_data} />
      </div>
      <div>
      <Line options={managemen_options} data={managemen_data} />
      </div>
      <div>
      <Line options={klerong_options} data={klerong_data} />
      </div>
      <div>
      <Line options={ether3_options} data={ether3_data} />
      </div>
      </div>

 


    </>
  );
};
export default BandwithMonitor;
