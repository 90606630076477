import axios from "axios";
import { setCookie } from "react-use-cookie";
import toast, { Toaster } from "react-hot-toast";
import { useState, useEffect } from "react";
import Navbar from "../Navbar";
import endpoint from "../endpoint";
import { Navigate } from "react-router-dom";
import { getCookie } from "react-use-cookie";

const LoginComponents = () => {
  const [auth, setAuth] = useState(false);

  useEffect(() => {
    let token = getCookie("token");
    if (token.length > 10) {
      setAuth(true);
    }
  }, [auth]);
  const handleSubmit = (event) => {
    event.preventDefault();
    let formData = new FormData(event.target);
    const LoginApi = `${endpoint}/rest/login`;
    axios
      .post(LoginApi, formData)
      .then((result) => {
        let BarrierToken = result.data.token;
        setCookie("token", BarrierToken);
        axios
          .get(`${endpoint}/rest/info`, {
            headers: { Authorization: `Bearer ${BarrierToken}` },
          })
          .then((value) => {
            setCookie("data", JSON.stringify(value.data));
            setAuth(true);
          });
        toast.success("Sukses login! anda akan diarahkan ke dashboard");
        setAuth(true);
      })
      .catch((error) => {
        if (!error.response.data) {
          toast.error("Kesalahan! Axios tidak dapat mengambil data server");
        } else {
          error.response.data.errors.map((value) => toast.error(value.message));
        }
      });
  };
  return (
    <>
      {auth ? <Navigate to="/helpdesk/dashboard" /> : ""}
      <Navbar />
      <Toaster position="top-right" reverseOrder={false} />
      <section className="py-12 md:py-12 bg-white">
        <div className="container px-4 mx-auto">
          <div className="max-w-sm mx-auto">
            <div className="mb-6 text-center">
              <div className="inline-block mb-6">
                <img className="h-16" src="/logo.png" alt="" />
              </div>
              <h3 className="mb-4 text-2xl md:text-3xl font-bold">Login</h3>
              <p className="text-lg text-coolGray-500 font-medium">
                Helpdesk PT Amanna Media Link
              </p>
            </div>
            <form onSubmit={handleSubmit} method="POST" action="">
              <div className="mb-6">
                <label className="block mb-2 text-coolGray-800 font-medium">
                  Nama Pengguna
                </label>
                <input
                  className="appearance-none block w-full p-3 leading-5 text-coolGray-900 border border-coolGray-200 rounded-lg shadow-md placeholder-coolGray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                  type="text"
                  name="nama_pengguna"
                  placeholder="Nama pengguna"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-coolGray-800 font-medium">
                  Kata Sandi
                </label>
                <input
                  className="appearance-none block w-full p-3 leading-5 text-coolGray-900 border border-coolGray-200 rounded-lg shadow-md placeholder-coolGray-400 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                  type="password"
                  name="kata_sandi"
                  placeholder="Kata sandi"
                  required
                />
              </div>
              <button
                className="inline-block py-3 px-7 mb-6 w-full text-base text-green-50 font-medium text-center leading-6 bg-green-500 hover:bg-green-600 focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 rounded-md shadow-sm"
                type="submit"
              >
                Masuk
              </button>
              <p className="text-center"></p>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginComponents;
