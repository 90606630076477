import { Fragment, useState, useEffect } from "react";
import endpoint from "../endpoint";
import { getCookie, setCookie } from "react-use-cookie";
//import ContentLoader from "react-content-loader";
import { Navigate } from "react-router-dom";
import NavHelpdesk from "./navhelpdesk";
import NavHelpdeskAdmin from "./navadmin";
import NavHelpdeskOwner from "./navowner";
import Heading from "./heading";
import axios from "axios";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import toast, { Toaster } from "react-hot-toast";

const DashboardComponents = () => {
  //MOdal
  let [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  function openModal(id) {
    setselectId(id);
    setIsOpen(true);
  }

  const [data, setData] = useState({
    total: 0,
    terbuka: 0,
    diproses: 0,
    selesai: 0,
  });

  useEffect(() => {
    const BarrierToken = getCookie("token");
    axios
      .get(`${endpoint}/rest/info`, {
        headers: { Authorization: `Bearer ${BarrierToken}` },
      })
      .then((data) => {
        let userdata = data.data;
        if (userdata.foto_profil_img !== null) {
          const imagepath = {
            foto_profil_img: endpoint + userdata.foto_profil_img,
          };
          delete data.data.foto_profil_img;
          Object.assign(userdata, imagepath);
          setState(data.data);
        } else {
          const imagepath = {
            foto_profil_img: "/user.png",
          };
          delete data.data.foto_profil_img;
          Object.assign(userdata, imagepath);
          setState(data.data);
        }
      })
      .catch((error) => {});
  }, []);

  const [selectId, setselectId] = useState("");
  const [state, setState] = useState({
    alamat: "",
    alamat_email: "",
    foto_profil_img: "",
    id_foto_profil: "",
    nama: "",
    nama_pengguna: "",
    no_telepon: "",
    role: "",
  });

  const TeruskanKeAdmin = () => {
    closeModal();
    console.log(selectId);
    const BarrierToken = getCookie("token");
    console.log(BarrierToken);
    toast.success("Done! Laporan diteruskan ke Teknisi");
    axios
      .put(
        endpoint + "/rest/aduan/teruskanKeTeknisi/" + selectId,
        {},
        {
          headers: { Authorization: `Bearer ${BarrierToken}` },
        }
      )
      .then((value) => {
        console.log(value);
        setRefreshKey((oldKey) => oldKey + 1);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [refreshKey, setRefreshKey] = useState(0);
  const [listAduan, setAduan] = useState([]);

  const [isLoading, setLoading] = useState(false);
  const [auth, setAuth] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState();

  useEffect(() => {
    let cookie = getCookie("token");
    console.log(getCookie("data"));
    console.log(cookie);
    if (cookie == null && cookie === undefined) {
      setAuth(false);
      setCookie("token", "");
      setCookie("data", "");
    } else {
      setLoading(true);
      fetch(endpoint + "/rest/aduan/overview", {
        headers: { Authorization: `Bearer ${cookie}` },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.errors) {
            setAuth(false);
            setCookie("token", "");
            setCookie("data", "");
          } else {
            setAuth(true);
            setData(data);
            setLoading(false);
          }
        })
        .catch((err) => {
          setAuth(false);
          setCookie("token", "");
          setCookie("data", "");
        });
      fetch(endpoint + "/rest/list/aduan?page=" + currentPage, {
        headers: { Authorization: `Bearer ${cookie}` },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.errors) {
            setAuth(false);
            setCookie("token", "");
            setCookie("data", "");
          } else {
            setAuth(true);
            setAduan(data.data);
            setCurrentPage(data.meta.current_page);
            setLastPage(data.meta.last_page);
          }
        });
    }
  }, [refreshKey, currentPage]);

  const Paginate = (id) => {
    console.log(id);
    setCurrentPage(id);
    setRefreshKey((oldKey) => oldKey + 1);
  };

  return (
    <>
      {auth ? "" : <Navigate to="/helpdesk/login"></Navigate>}

      {state.role === "Admin" ? (
        <NavHelpdeskAdmin
          username={state.nama_pengguna}
          role={state.role}
          image={state.foto_profil_img}
        />
      ) : (
        ""
      )}
      {state.role === "Pelanggan" || state.role === "Teknisi" ? (
        <NavHelpdesk
          username={state.nama_pengguna}
          role={state.role}
          image={state.foto_profil_img}
        />
      ) : (
        ""
      )}
      {state.role === "Owner" ? (
        <NavHelpdeskOwner
          username={state.nama_pengguna}
          role={state.role}
          image={state.foto_profil_img}
        />
      ) : (
        ""
      )}

      <Heading pageName="Dashboard" />

      <Toaster position="top-right" reverseOrder={false} />

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Teruskan ke Teknisi?
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Aduan ini akan diteruskan ke teknisi lapangan.
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center py-2 px-6 text-white bg-green-500 hover:bg-green-600 rounded-lg mr-2"
                      onClick={TeruskanKeAdmin}
                    >
                      Konfirmasi
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center py-2 px-6 text-white bg-red-500 hover:bg-red-600 rounded-lg"
                      onClick={closeModal}
                    >
                      Batal
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <section className="pt-8 pb-2 bg-white">
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full md:w-1/4 px-3 mb-6">
              <div className=" text-center p-6 shadow bg-blue-700 rounded">
                <div className="flex text-center justify-between">
                  {isLoading ? (
                    <h3> Loading... </h3>
                  ) : (
                    <p className="text-sm md:text-base items-center text-center text-white">
                      Total Aduan
                    </p>
                  )}
                </div>
                <p className="text-3xl text-white lg:text-4xl font-bold font-heading">
                  {isLoading ? <h3> Loading... </h3> : data.total}
                </p>
              </div>
            </div>

            <div className="w-full md:w-1/4 px-3 mb-6">
              <div className="p-6 shadow bg-blue-600 rounded">
                <div className="flex justify-between">
                  {isLoading ? (
                    <h3> Loading... </h3>
                  ) : (
                    <p className="text-sm md:text-base text-white">
                      Aduan Terbuka
                    </p>
                  )}
                </div>
                <p className="text-3xl text-white lg:text-4xl font-bold font-heading">
                  {isLoading ? <h3> Loading... </h3> : data.terbuka}
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/4 px-3 mb-6">
              <div className="p-6 shadow bg-orange-600 rounded">
                <div className="flex justify-between">
                  {isLoading ? (
                    <h3> Loading... </h3>
                  ) : (
                    <p className=" text-sm md:text-base text-white">
                      Aduan Diproses
                    </p>
                  )}
                </div>
                <p className="text-3xl text-white lg:text-4xl font-bold font-heading">
                  {isLoading ? <h3> Loading... </h3> : data.diproses}
                </p>
              </div>
            </div>
            <div className="w-full md:w-1/4 px-3 mb-6">
              <div className="p-6 shadow bg-green-600 rounded">
                <div className="flex justify-between">
                  {isLoading ? (
                    <h3> Loading... </h3>
                  ) : (
                    <p className="text-sm md:text-base text-white">
                      Aduan Selesai
                    </p>
                  )}
                </div>
                <p className="text-3xl text-white lg:text-4xl font-bold font-heading">
                  {isLoading ? <h3> Loading... </h3> : data.selesai}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-20 bg-blueGray-50">
        <div className="container px-4 mx-auto mb-3">
          <h2 className="mb-1 text-center text-2xl font-bold text-black">
            {" "}
            Daftar Aduan{" "}
          </h2>{" "}
          <br />
          <table className="w-full bg-white rounded shadow">
            <thead>
              <tr className="text-left text-white text-bold text-xs bg-slate-800">
                <th className="pl-6 py-4 font-normal">#ID</th>
                <th className="px-4 py-4 font-normal">Judul Aduan</th>
                <th className="px-4 py-4 font-normal">Kategori</th>
                <th className="px-4 py-4 font-normal">Level Aduan</th>
                <th className="px-4 py-4 font-normal">Status</th>
                <th className="px-4 py-4 font-normal">Progress</th>
                <th className="px-4 py-4 font-normal">Dibuat Oleh</th>
                <th className="px-4 py-4 font-normal">Aksi</th>
              </tr>
            </thead>
            <tbody>
              {listAduan.map((value, key) => {
                return (
                  <tr key={key} className="border-t border-blueGray-100">
                    <td className="flex px-6 py-4 text-xs">{value.id_aduan}</td>
                    <td className="px-4 py-2 text-xs text-left font-semibold">
                      {value.judul_aduan}
                    </td>

                    {value.kategori === "Jaringan" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <span className="inline-block py-1 px-3 text-xs text-red-600 bg-red-100 font-semibold uppercase rounded-full">
                            {value.kategori}
                          </span>
                        </div>
                      </td>
                    ) : null}

                    {value.kategori === "Pembayaran" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <span className="inline-block py-1 px-3 text-xs text-green-600 bg-green-100 font-semibold uppercase rounded-full">
                            {value.kategori}
                          </span>
                        </div>
                      </td>
                    ) : null}

                    {value.kategori === "Pendaftaran" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <span className="inline-block py-1 px-3 text-xs text-blue-600 bg-blue-100 font-semibold uppercase rounded-full">
                            {value.kategori}
                          </span>
                        </div>
                      </td>
                    ) : null}

                    {/**  LEVEL ADUAN  */}

                    {value.level_aduan === "tinggi" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <span className="inline-block py-1 px-3 text-xs text-red-600 bg-red-100 font-semibold uppercase rounded-full">
                            {value.level_aduan}
                          </span>
                        </div>
                      </td>
                    ) : null}

                    {value.level_aduan === "normal" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <span className="inline-block py-1 px-3 text-xs text-green-600 bg-green-100 font-semibold uppercase rounded-full">
                            {value.level_aduan}
                          </span>
                        </div>
                      </td>
                    ) : null}

                    {value.level_aduan === "rendah" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <span className="inline-block py-1 px-3 text-xs text-blue-600 bg-blue-100 font-semibold uppercase rounded-full">
                            {value.level_aduan}
                          </span>
                        </div>
                      </td>
                    ) : null}

                    {/**  END LEVEL ADUAN  */}

                    {/**  STATUS ADUAN  */}
                    {value.status_aduan === "terbuka" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <span className="inline-block py-1 px-3 text-xs text-blue-600 bg-blue-100 font-semibold uppercase rounded-full">
                            {value.status_aduan}
                          </span>
                        </div>
                      </td>
                    ) : null}
                    {value.status_aduan === "diproses" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <span className="inline-block py-1 px-3 text-xs text-orange-600 bg-orange-100 font-semibold uppercase rounded-full">
                            {value.status_aduan}
                          </span>
                        </div>
                      </td>
                    ) : null}
                    {value.status_aduan === "selesai" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <span className="inline-block py-1 px-3 text-xs text-green-600 bg-green-100 font-semibold uppercase rounded-full">
                            {value.status_aduan}
                          </span>
                        </div>
                      </td>
                    ) : null}

                    {/**  PROGRESS ADUAN  */}

                    {value.status_aduan === "selesai" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <p className="text-xs font-semibold">100%</p>
                          <div className="ml-2 w-24 h-1 rounded-xl bg-blueGray-200">
                            <div className="w-18 h-1 rounded-xl bg-green-500" />
                          </div>
                        </div>
                      </td>
                    ) : null}
                    {value.status_aduan === "terbuka" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <p className="text-xs font-semibold">0%</p>
                          <div className="ml-2 w-1 h-1 rounded-xl bg-blueGray-200">
                            <div className="w-18 h-1 rounded-xl bg-blue-500" />
                          </div>
                        </div>
                      </td>
                    ) : null}
                    {value.status_aduan === "diproses" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <p className="text-xs font-semibold">50%</p>
                          <div className="ml-2 w-12 h-1 rounded-xl bg-blueGray-200">
                            <div className="w-18 h-1 rounded-xl bg-orange-500" />
                          </div>
                        </div>
                      </td>
                    ) : null}

                      <td>
                        <div className="flex pl-4 items-center">
                         {value.nama} ({value.role})
                        </div>
                      </td>

                    <td className="px-4 py-2 text-xs text-left font-semibold">
                      <Link to={"/helpdesk/aduan/detail/" + value.id_aduan}>
                        <button className="text-white inline-block hover:bg-blue-400 bg-blue-600 py-2 px-5 rounded mr-2">
                          Lihat
                        </button>
                      </Link>
                      {state.role === "Admin" ? (
                        <button
                          className="text-white inline-block hover:bg-orange-400 bg-orange-600 py-2 px-5 rounded"
                          onClick={() => openModal(value.id_aduan)}
                        >
                          Teruskan Ke Teknisi
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex justify-center items-center space-x-1">
          {[...Array(lastPage)].map((key, value) => (
            <button
              onClick={() => Paginate(value + 1)}
              key={value + 1}
              className={
                value + 1 === currentPage
                  ? "px-4 py-2 text-white bg-blue-400 rounded-md hover:bg-blue-400 hover:text-white"
                  : "px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-blue-400 hover:text-white"
              }
            >
              {value + 1}
            </button>
          ))}
        </div>
      </section>
    </>
  );
};

export default DashboardComponents;
