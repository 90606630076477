import { useState, useEffect } from "react";
import { getCookie } from "react-use-cookie";
import { Navigate } from "react-router-dom";
const HelpdeskComponents = () => {
  const [auth, setAuth] = useState(false);
  useEffect(() => {
    const token = getCookie("token");
    if (token !== "null" && token !== "") {
      setAuth(false);
    } else {
      setAuth(true);
    }
  }, []);

  return (
    <>
      {auth ? (
        <Navigate to={"/helpdesk/dashboard"} replace={true} />
      ) : (
        <Navigate to={"/helpdesk/login"} replace={true} />
      )}
    </>
  );
};

export default HelpdeskComponents;
