import * as React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Cakupan from "./components/Cakupan";
import Layanan from "./components/Layanan";
import Harga from "./components/Harga";
import Kontak from "./components/Kontak";
import HelpdeskComponents from "./components/helpdesk";
import LoginComponents from "./components/helpdesk/login";
import DashboardComponents from "./components/helpdesk/dashboard";
import Aduan from "./components/helpdesk/aduan";
import DetailAduanComponents from "./components/helpdesk/detailaduan";
import Logout from "./components/helpdesk/logout";
import ProfileComponents from "./components/helpdesk/profile";
import PenggunaComponents from "./components/helpdesk/pengguna";
import EditPengguna from "./components/helpdesk/editpengguna";
import MonitoringJaringan from "./components/helpdesk/monitoring_jaringan";
import BandwithMonitor from "./components/helpdesk/bandwith-monitor";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cakupan" element={<Cakupan />} />
        <Route path="/layanan" element={<Layanan />} />
        <Route path="/harga" element={<Harga/>} />
        <Route path="/kontak" element={<Kontak/>} />

        <Route path="/helpdesk" element={<HelpdeskComponents/>} />
        <Route path="/helpdesk/login" element={<LoginComponents/>} />
        <Route path="/helpdesk/logout" element={<Logout/>} />
        <Route path="/helpdesk/dashboard" element={<DashboardComponents/>} />
        <Route path="/helpdesk/aduan" element={<Aduan/>} />
        <Route path="/helpdesk/aduan/detail/:id" element={<DetailAduanComponents/>} />
        <Route path="/helpdesk/profil" element={<ProfileComponents/>} />
        <Route path="/helpdesk/pengguna" element={<PenggunaComponents/>} />
        <Route path="/helpdesk/pengguna/edit/:id" element={<EditPengguna/>} />
        <Route path="/helpdesk/monitor" element={<MonitoringJaringan/>} />
        <Route path="/helpdesk/bandwith-monitor" element={<BandwithMonitor/>} />

      </Routes>
    </div>
  );
}

export default App;