import Navbar from "./Navbar";
import LandingCakupan from "./landing/Cakupan";
import Footer from "./Footer";
const Landing = () => {

    document.title = "Cakupan - PT Amanna Media Link"

    return(
        <>
        <Navbar/>
        <LandingCakupan/>
        <Footer/>
        </>
    )

}

export default Landing;