import { useState, useEffect } from "react";
import { getCookie, setCookie } from "react-use-cookie";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import endpoint from "../endpoint";
import { Link, Navigate } from "react-router-dom";
import NavHelpdesk from "./navhelpdesk";
import NavHelpdeskAdmin from "./navadmin";
import NavHelpdeskOwner from "./navowner";
import Heading from "./heading";
import toast, { Toaster } from "react-hot-toast";
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const Aduan = () => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          alt={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  const [BarrierToken, setBarrierToken] = useState(false);
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount

    const token = getCookie("token");
    setBarrierToken(token);
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const [auth, setAuth] = useState(false);
  const [listAduan, setAduan] = useState([]);

  const [refreshKey, setRefreshKey] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const Paginate = (id) => {
    setCurrentPage(id);
    setRefreshKey((oldKey) => oldKey + 1);
  };
  useEffect(() => {
    let token = getCookie("token");
    fetch(endpoint + "/rest/list/aduan?page=" + currentPage, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.errors) {
          setAuth(false);
          setCookie("token", "");
          setCookie("data", "");
        } else {
          setAduan(data.data);
          setCurrentPage(data.meta.current_page);
          setLastPage(data.meta.last_page);
        }
      });
  }, [refreshKey, currentPage]);

  useEffect(() => {
    const BarrierToken = getCookie("token");
    axios
      .get(`${endpoint}/rest/info`, {
        headers: { Authorization: `Bearer ${BarrierToken}` },
      })
      .then((data) => {
        let userdata = data.data;
        if (userdata.foto_profil_img !== null) {
          const imagepath = {
            foto_profil_img: endpoint + userdata.foto_profil_img,
          };
          delete data.data.foto_profil_img;
          Object.assign(userdata, imagepath);
          setState(data.data);
        } else {
          const imagepath = {
            foto_profil_img: "/user.png",
          };
          delete data.data.foto_profil_img;
          Object.assign(userdata, imagepath);
          setState(data.data);
        }
      })
      .catch((error) => {});
  }, []);

  const [state, setState] = useState({
    alamat: "",
    alamat_email: "",
    foto_profil_img: "",
    id_foto_profil: "",
    nama: "",
    nama_pengguna: "",
    no_telepon: "",
    role: "",
  });

  const AddAduan = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    files.map((file) => data.append("File[]", file));
    axios
      .post(endpoint + "/rest/tambah/aduan", data, {
        headers: {
          Authorization: `Bearer ${BarrierToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        toast.success("Sukses menambah aduan!");
        setRefreshKey((oldKey) => oldKey + 1);
        console.log(data);
      });
  };

  return (
    <>
      {auth ? (
        <Navigate to={"/helpdesk/dashboard"} replace={true}></Navigate>
      ) : (
        ""
      )}

      {state.role === "Admin" ? (
        <NavHelpdeskAdmin
          username={state.nama_pengguna}
          role={state.role}
          image={state.foto_profil_img}
        />
      ) : (
        ""
      )}
      {state.role === "Pelanggan" || state.role === "Teknisi" ? (
        <NavHelpdesk
          username={state.nama_pengguna}
          role={state.role}
          image={state.foto_profil_img}
        />
      ) : (
        ""
      )}
      {state.role === "Owner" ? (
        <NavHelpdeskOwner
          username={state.nama_pengguna}
          role={state.role}
          image={state.foto_profil_img}
        />
      ) : (
        ""
      )}
      <Heading pageName="Aduan" />

      <Toaster position="top-right" reverseOrder={false} />
      <section className="text-gray-700 body-font relative">
        <div className="container px-2 py-12 mx-auto">
          <div className="flex flex-col text-center w-full mb-2">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
              Tambah Aduan
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              Anda dapat menambahkan aduan dengan mengisi form dibawah ini
            </p>
          </div>
          <form action="" method="POST" onSubmit={AddAduan}>
            <div className="lg:w-1/2 md:w-2/3 mx-auto">
              <div className="flex flex-wrap -m-2">
                <div className="p-2 w-full">
                  <div className="relative">
                    <label
                      htmlFor="name"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Judul Aduan
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="judul_aduan"
                      className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      required
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="kategori"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                    >
                      Kategori
                    </label>
                    <select
                      required
                      name="id_kategori"
                      id="kategori"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="1">Jaringan</option>
                      <option value="2">Pembayaran</option>
                      <option value="3">Pendaftaran</option>
                    </select>
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label
                      htmlFor="level"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                    >
                      Level Aduan
                    </label>
                    <select
                      required
                      name="level_aduan"
                      id="level"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="normal">Normal</option>
                      <option value="tinggi">Tinggi</option>
                      <option value="rendah">Rendah</option>
                    </select>
                  </div>
                </div>

                <div className="p-2 w-full">
                  <div className="relative">
                    <label
                      htmlFor="message"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Isi Aduan
                    </label>
                    <textarea
                      id="message"
                      name="isi_aduan"
                      className="w-full bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                      required
                    ></textarea>
                    <label
                      htmlFor="foto"
                      className="leading-7 text-sm text-gray-600"
                    >
                      Foto (Jika ada)
                    </label>
                    <section className="container">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <div className="w-full">
                          <label className="flex justify-center items-center h-32 w-full px-4 py-5 transition bg-gray-100  border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                            <span className="flex items-center space-x-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 text-gray-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                />
                              </svg>
                              <span className="font-medium text-gray-600">
                                Drop gambar disini atau
                                <span className="text-blue-600 ">
                                  {" "}
                                  Pilih File
                                </span>
                              </span>
                            </span>
                            <input type="file" className="hidden" />
                          </label>
                        </div>
                      </div>
                      <aside style={thumbsContainer}>{thumbs}</aside>
                    </section>
                  </div>
                </div>
                <div className="p-2 w-full">
                  <button className="flex mx-auto text-white bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-600 rounded text-lg">
                    Kirim
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      <section className="py-20 bg-blueGray-50">
        <div className="container px-4 mx-auto mb-3">
          <h2 className="mb-1 text-center text-2xl font-bold text-black">
            {" "}
            Daftar Aduan{" "}
          </h2>{" "}
          <br />
          <table className="w-full bg-white rounded shadow">
            <thead>
              <tr className="text-left text-white text-bold text-xs bg-slate-800">
                <th className="pl-6 py-4 font-normal">#ID</th>
                <th className="px-4 py-4 font-normal">Judul Aduan</th>
                <th className="px-4 py-4 font-normal">Kategori</th>
                <th className="px-4 py-4 font-normal">Level Aduan</th>
                <th className="px-4 py-4 font-normal">Status</th>
                <th className="px-4 py-4 font-normal">Progress</th>
                <th className="px-4 py-4 font-normal">Aksi</th>
              </tr>
            </thead>
            <tbody>
              {listAduan.map((value, key) => {
                return (
                  <tr key={key} className="border-t border-blueGray-100">
                    <td className="flex px-6 py-4 text-xs">{value.id_aduan}</td>
                    <td className="px-4 py-2 text-xs text-left font-semibold">
                      {value.judul_aduan}
                    </td>

                    {value.kategori === "Jaringan" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <span className="inline-block py-1 px-3 text-xs text-red-600 bg-red-100 font-semibold uppercase rounded-full">
                            {value.kategori}
                          </span>
                        </div>
                      </td>
                    ) : null}

                    {value.kategori === "Pembayaran" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <span className="inline-block py-1 px-3 text-xs text-green-600 bg-green-100 font-semibold uppercase rounded-full">
                            {value.kategori}
                          </span>
                        </div>
                      </td>
                    ) : null}

                    {value.kategori === "Pendaftaran" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <span className="inline-block py-1 px-3 text-xs text-blue-600 bg-blue-100 font-semibold uppercase rounded-full">
                            {value.kategori}
                          </span>
                        </div>
                      </td>
                    ) : null}

                    {/**  LEVEL ADUAN  */}

                    {value.level_aduan === "tinggi" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <span className="inline-block py-1 px-3 text-xs text-red-600 bg-red-100 font-semibold uppercase rounded-full">
                            {value.level_aduan}
                          </span>
                        </div>
                      </td>
                    ) : null}

                    {value.level_aduan === "normal" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <span className="inline-block py-1 px-3 text-xs text-green-600 bg-green-100 font-semibold uppercase rounded-full">
                            {value.level_aduan}
                          </span>
                        </div>
                      </td>
                    ) : null}

                    {value.level_aduan === "rendah" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <span className="inline-block py-1 px-3 text-xs text-blue-600 bg-blue-100 font-semibold uppercase rounded-full">
                            {value.level_aduan}
                          </span>
                        </div>
                      </td>
                    ) : null}

                    {/**  END LEVEL ADUAN  */}

                    {/**  STATUS ADUAN  */}
                    {value.status_aduan === "terbuka" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <span className="inline-block py-1 px-3 text-xs text-blue-600 bg-blue-100 font-semibold uppercase rounded-full">
                            {value.status_aduan}
                          </span>
                        </div>
                      </td>
                    ) : null}
                    {value.status_aduan === "diproses" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <span className="inline-block py-1 px-3 text-xs text-orange-600 bg-orange-100 font-semibold uppercase rounded-full">
                            {value.status_aduan}
                          </span>
                        </div>
                      </td>
                    ) : null}
                    {value.status_aduan === "selesai" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <span className="inline-block py-1 px-3 text-xs text-green-600 bg-green-100 font-semibold uppercase rounded-full">
                            {value.status_aduan}
                          </span>
                        </div>
                      </td>
                    ) : null}

                    {/**  PROGRESS ADUAN  */}

                    {value.status_aduan === "selesai" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <p className="text-xs font-semibold">100%</p>
                          <div className="ml-2 w-24 h-1 rounded-xl bg-blueGray-200">
                            <div className="w-18 h-1 rounded-xl bg-green-500" />
                          </div>
                        </div>
                      </td>
                    ) : null}
                    {value.status_aduan === "terbuka" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <p className="text-xs font-semibold">25%</p>
                          <div className="ml-2 w-24 h-1 rounded-xl bg-blueGray-200">
                            <div className="w-18 h-1 rounded-xl bg-blue-500" />
                          </div>
                        </div>
                      </td>
                    ) : null}
                    {value.status_aduan === "diproses" ? (
                      <td>
                        <div className="flex pl-4 items-center">
                          <p className="text-xs font-semibold">50%</p>
                          <div className="ml-2 w-24 h-1 rounded-xl bg-blueGray-200">
                            <div className="w-18 h-1 rounded-xl bg-orange-500" />
                          </div>
                        </div>
                      </td>
                    ) : null}

                    <td className="px-4 py-2 text-xs text-left font-semibold">
                      <Link to={"/helpdesk/aduan/detail/" + value.id_aduan}>
                        <button className="text-white inline-block hover:bg-blue-400 bg-blue-600 py-2 px-5 rounded">
                          Lihat
                        </button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="flex justify-center items-center space-x-1">
          {[...Array(lastPage)].map((key, value) => (
            <button
              onClick={() => Paginate(value + 1)}
              key={value + 1}
              className={
                value + 1 === currentPage
                  ? "px-4 py-2 text-white bg-blue-400 rounded-md hover:bg-blue-400 hover:text-white"
                  : "px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-blue-400 hover:text-white"
              }
            >
              {value + 1}
            </button>
          ))}
        </div>
      </section>
    </>
  );
};

export default Aduan;
