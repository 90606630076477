const LandingHome = () => {

    return(
        <>
      <section className="text-white body-font bg-slate-800">
        {" "}
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
              <img
                className="object-cover object-center rounded"
                alt="hero"
                src="/assets/network.png"
              />
            </div>
            <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-5 md:mb-0 items-center text-center">
              <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
                ISP (Internet Service Provider)
              </h1>
              <h2 className="title-font sm:text-2xl text-2xl mb-4 font-medium text-white">
                PT AMANNA MEDIA LINK
              </h2>
              <p className="mb-8 leading-relaxed">
                Kami menyediakan layanan internet untuk rumah maupun di kantor
                dengan tarif yang terjangkau serta latency yang rendah dan
                uptime sampai dengan 99%
              </p>
              <div className="flex justify-center">
                <button
                  onClick={() => {
                    window.open("https://wa.me/6281225412414", "_blank");
                  }}
                  type="button"
                  className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2"
                >
                  <img
                    alt="whatsapp"
                    src="/assets/whatsapp.gif"
                    className="w-10 h-10"
                  ></img>
                  Kontak Kami Melalui Whatsapp
                </button>
              </div>
            </div>
        </div>
      </section>
      <section className="text-gray-700 body-font "></section>{" "}
      <section className="text-white bg-slate-900 body-font">
        {" "}
        <div className="container px-5 py-24 mx-auto ">
          <div className="flex flex-col text-center w-full mb-5">
            <h1 className="sm:text-3xl text-2xl font-medium title-font text-white">
              Mengapa Memilih Kami?
            </h1>
            <p className="leading-relaxed text-base">
              Ratusan pengguna dan beberapa perusahaan sudah memilih kami
            </p>
          </div>
            <div className="flex flex-wrap -m-4">
              <div className="p-4 md:w-1/3">
                <div className="flex rounded-lg text-black h-full bg-white p-8 flex-col">
                  <div className="flex items-center mb-3">
                    <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-gray-100 text-white flex-shrink-0">
                      <img alt="support" src="/assets/support.png"></img>
                    </div>
                    <h2 className="text-gray-900 text-lg title-font font-medium">
                      24/7 Support
                    </h2>
                  </div>
                  <div className="flex-grow">
                    <p className="leading-relaxed text-base">
                      Teknisi kami siap support selama 24 jam 7 hari, dan
                      menyelesaikan masalah anda.
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-4 md:w-1/3">
                <div className="flex rounded-lg h-full bg-white p-8 flex-col">
                  <div className="flex items-center mb-3">
                    <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-gray-100 text-black flex-shrink-0">
                      <img alt="speedtest" src="/assets/speedtest.png"></img>
                    </div>
                    <h2 className="text-gray-900 text-lg title-font font-medium">
                      Kecepatan Tinggi
                    </h2>
                  </div>
                  <div className="flex-grow">
                    <p className="leading-relaxed text-base text-black">
                      Kapasitas internet yang tinggi mencapai 1gbps, melakukan
                      browsing, streaming tanpai delay.
                    </p>
                  </div>
                </div>
              </div>
              <div className="p-4 md:w-1/3">
                <div className="flex rounded-lg h-full bg-white p-8 flex-col">
                  <div className="flex items-center mb-3">
                    <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-gray-100 text-white flex-shrink-0">
                      <img alt="speedtest" src="/assets/wifi.png"></img>
                    </div>
                    <h2 className="text-gray-900 text-lg title-font font-medium">
                      Stabil
                    </h2>
                  </div>
                  <div className="flex-grow">
                    <p className="leading-relaxed text-base text-black">
                      Router kami langsung terhubung ke pusat data indonesia,
                      sehingga kualitas koneksi kami stabil dan responsive
                    </p>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>
      <section className="text-white body-font bg-slate-800">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
            <div className="lg:flex-grow md:w-1/2 lg : pr-24 md:pr-16 flex flex-col md : items-start md:text-left mb-5 md:mb-0 text-center ">
              <h2 className="title-font sm:text-2xl text-2xl mb-4 font-medium text-white">
                {" "}
                Tertarik Menjadi Reseller ?{" "}
              </h2>
              <p className="mb-8 leading-relaxed">
                Jika anda tertarik menjadi reseller kami silahkan hubungi kami
                melalui link dibawah ini.ada penawaran menarik untuk anda
              </p>{" "}
              <div className="flex justify-center">
                {" "}
                <button
                  onClick={() => {
                    window.open("https://wa.me/6281225412414", "_blank");
                  }}
                  type="button"
                  className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2"
                >
                  <img
                    alt="whatsapp"
                    src="/assets/whatsapp.gif"
                    className="w-10 h-10"
                  ></img>
                  Kontak Kami Melalui Whatsapp
                </button>
              </div>
            </div>
            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
              <img
                className="object-cover object-center rounded"
                alt="hero"
                src="/assets/internet.gif"
              />
            </div>
        </div>
      </section>
        </>
    )

}

export default LandingHome;